import { GuideEpisodesHeader } from '../GuideEpisodesHeader/GuideEpisodesHeader.component';
import React, { FC } from 'react';
import { ContentCardProps } from '@components/content/ContentCard/ContentCard.types';
import { TextWithArrow } from '@components/Text/TextWithArrow.component';
import { ContentCard } from '@content/ContentCard/ContentCard.component';
import { getSlugLinkProps, urlEnum } from '@utils/url';
import classnames from 'classnames';

type Props = Omit<ContentCardProps, 'cardSubTitle' | 'cardActions' | 'href'> & { slug: string; episodesCount: number };

const GuideCard: FC<Props> = ({ title, slug, episodesCount, className, ...rest }) => {
  const linkProps = getSlugLinkProps(urlEnum.guides, slug);
  const rootClass = classnames('guide-card', className);
  const cardSubTitle = <GuideEpisodesHeader count={episodesCount} />;
  const cardActions = <TextWithArrow i18nKey="guides.link" tag="span" variant="p" />;

  return (
    <ContentCard cardActions={cardActions} cardSubTitle={cardSubTitle} className={rootClass} {...linkProps} {...rest} />
  );
};

export { GuideCard };
