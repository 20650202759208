import { Content, EventDetail } from 'pleinchamp-api-client';
import React, { FC } from 'react';
import { ContentCardProps } from '@components/content/ContentCard/ContentCard.types';
import { YellowSunIllu } from '@components/illustrations/YellowSun.illu';
import { Text } from '@components/Text/Text.component';
import { TextWithArrow } from '@components/Text/TextWithArrow.component';
import { ContentCard } from '@content/ContentCard/ContentCard.component';
import { getEventFormatedDate } from '@events/business/events.utils';
import { EventLocation, EventLocationVariant } from '@events/components/EventLocation/EventLocation.component';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { getSlugLinkProps, urlEnum } from '@utils/url';
import classnames from 'classnames';

import styles from './EventCard.module.scss';

type Props = Omit<ContentCardProps, 'cardImage' | 'cardSubTitle' | 'cardActions' | 'href' | 'isPro'> & {
  slug: Content['slug'];
  startDate?: EventDetail['startDate'];
  endDate?: EventDetail['endDate'];
  address: EventDetail['address'];
};

const EventCard: FC<Props> = ({ startDate, endDate, address, className, slug, ...rest }) => {
  const linkProps = getSlugLinkProps(urlEnum.event, slug);
  const rootClass = classnames(styles['event'], className);
  const isMobile = useIsBreakpointDown(Breakpoint.s);
  const cardSubTitle = <EventLocation address={address} variant={EventLocationVariant.small} />;
  const cardActions = <TextWithArrow i18nKey="events.card-action" tag="span" variant="p" />;

  const cardImage = (
    <div className={styles["card-image-event"]}>
    <div className={styles["content-event"]}> 
    <Text className={styles["event-card-image-title"]} flavour="light" i18nKey="events.title" tag="p" variant="h6" />
    <div className={styles["event-card-image-text"]}>
      {getEventFormatedDate(startDate, endDate).map(s => (
        <Text key={s} flavour="yellow" tag="p" variant={isMobile ? 'h5' : 'h3'}>
          {s}
        </Text>
      ))}
    </div>
    <YellowSunIllu className={styles["event-card-illu"]}/>
  </div>
  </div>
  );

  return (
    <ContentCard
      cardActions={cardActions}
      cardImage={cardImage}
      cardSubTitle={cardSubTitle}
      className={rootClass}
      {...linkProps}
      {...rest}
    />
  );
};

export { EventCard };
