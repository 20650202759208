import { Address } from 'pleinchamp-api-client';
import React, { FC } from 'react';
import { TinnyPinIcon } from '@components/icons/TinnyPin.icon';
import { Text } from '@components/Text/Text.component';
import { HTMLPropsHeaders, TextProps } from '@components/Text/Text.types';
import classnames from 'classnames';

export enum EventLocationVariant {
  normal = 'normal',
  small = 'small',
}

type Props = HTMLPropsHeaders & {
  address: Address;
  variant?: EventLocationVariant;
};

const EventLocation: FC<Props> = ({ address, variant = EventLocationVariant.normal, className, ...rest }) => {
  let location = address.country;

  if (address.countryCode.toLowerCase() === 'fr' && address.stateOrProvince) {
    location = address.stateOrProvince;

    if (address.stateOrProvinceCode) {
      location += ` (${address.stateOrProvinceCode})`;
    }
  }

  const iconSize = variant === EventLocationVariant.small ? 18 : 24;
  const textVariant: TextProps['variant'] = variant === EventLocationVariant.normal ? 'p' : 'h6';
  const rootClass = classnames('event-location flex align-center white-space-nowrap', className);

  return (
    <Text className={rootClass} tag="span" variant={textVariant} {...rest}>
      <TinnyPinIcon className=" plc-mr-xs" height={iconSize} width={iconSize} />
      {location}
    </Text>
  );
};

export { EventLocation };
