import React, { ComponentPropsWithRef, FC } from 'react';
import { Text } from '@components/Text/Text.component';
import { formatNumberWithComma } from '@utils/number';
import classnames from 'classnames';

import styles from './ServiceRating.module.scss';

type Props = ComponentPropsWithRef<'div'> & {
  rating: number;
  variant?: 'standard' | 'small';
};

const ServiceRating: FC<Props> = ({ rating, variant = 'standard', className, ...rest }) => {
  const isSmall = variant === 'small';
  const rateWidth = Math.round((rating / 5) * 100);
  const align = isSmall ? 'align-center' : 'align-baseline';
  const rootClass = classnames(styles['service-rating'],'flex', align, variant, className);

  return (
    <div className={rootClass} {...rest}>
      <div className="flex align-baseline">
        <Text flavour="green" tag="span" variant={isSmall ? 'h4' : 'h2'}>
          {formatNumberWithComma(rating, 1)}
        </Text>
        <Text flavour={isSmall ? 'grey' : 'dark'} tag="span" variant={isSmall ? 'h6' : 'h4'}>
          /5
        </Text>
      </div>
      <div className={`${styles['service-rating-rate-container']} ${isSmall ? 'plc-mr-xs' : 'plc-ml-s'}`}>
        <div className={styles["service-rating-rate"]} style={{ width: `${rateWidth}%` }} />
      </div>
    </div>
  );
};

export { ServiceRating };
