/* eslint-disable no-irregular-whitespace */
import { ContentTagList } from '../ContentTagList/ContentTagList.component';
import React, { FC } from 'react';
import { ProTag } from '@components/ProTag/ProTag.component';
import { Text } from '@components/Text/Text.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { urlEnum } from '@utils/url';
import classnames from 'classnames';
import styles from './ContentCard.module.scss';
import { ContentCardProps } from './ContentCard.types';

const ContentCard: FC<ContentCardProps> = ({
  cardImage,
  cardImageCentered = false,
  cardSubTitle,
  cardTitle,
  cardActions,
  cardHeaderTop,
  cardHeaderBottom,
  href,
  as,
  className,
  index,
  isPro = false,
  tags = [],
  ...rest
}) => {
  const [animationState, setAnimationState] = React.useState(index ? 'paused' : 'running');

  React.useEffect(() => {
    if (!index) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
    const timeoutId = setTimeout(() => setAnimationState('running'), index * 150);
    return () => clearTimeout(timeoutId);
  });

  const rootClass = classnames(styles['content-card'], 'flex column plc-mb-s', animationState, className);
  const cardImageClass = classnames(styles['card-image'], { centered: cardImageCentered });
  const article = (
    <article className={rootClass} {...rest}>
      <header>
        {(cardHeaderTop || tags.length > 0) && (
          <div className={styles['card-header-top']}>
            {cardHeaderTop}
            {!cardHeaderTop && tags.length > 0 && (
              <ContentTagList asLink={false} className="justify-end" oneLine={true} tags={tags} />
            )}
          </div>
        )}
        {cardImage && <div className={cardImageClass}>{cardImage}</div>}
        {(cardHeaderBottom || isPro) && (
          <div className={styles['card-header-bottom']}>
            {cardHeaderBottom}
            {!cardHeaderBottom && isPro && <ProTag className={styles["card-pro-tag"]} />}
          </div>
        )}
      </header>
      <div className="flex column flex-1 plc-pt-m">
        {cardSubTitle && (
          <Text className="plc-mb-xxs" tag="p" variant="h6">
            {cardSubTitle}
          </Text>
        )}
        
        <Text className={`${styles['card-title']} plc-mb-up-m-m`} tag="p" variant="h4">
          {cardTitle}
        </Text>
        {cardActions && <div>{cardActions}</div>}
      </div>
    </article>
  );

  if (!href) {
    return article;
  }

  return (
    <PlcAnchor linkProps={{ as, href }} niveau3={href.toString().includes(urlEnum.guides) ? cardTitle?.toString() : ''}>
      {article}
    </PlcAnchor>
  );
};

export { ContentCard };
