import React, { FC } from 'react';
import { ContentCardProps } from '@components/content/ContentCard/ContentCard.types';
import { TextWithArrow } from '@components/Text/TextWithArrow.component';
import { ContentCard } from '@content/ContentCard/ContentCard.component';
import { formatDateCompact, formatDateToIso } from '@utils/date';
import { getSlugLinkProps, urlEnum } from '@utils/url';
import classnames from 'classnames';

type Props = Omit<ContentCardProps, 'cardSubTitle' | 'cardActions' | 'href'> & { slug: string; date: Date };

const ActuCard: FC<Props> = ({ date, className, slug, ...rest }) => {
  const linkProps = getSlugLinkProps(urlEnum.actu, slug);
  const rootClass = classnames('actu-card', className);
  const cardSubTitle = date ? <time dateTime={formatDateToIso(date)}>{formatDateCompact(date)}</time> : null;
  const cardActions = <TextWithArrow i18nKey="actu.card-action" tag="span" variant="p" />;

  return (
    <ContentCard cardActions={cardActions} cardSubTitle={cardSubTitle} className={rootClass} {...linkProps} {...rest} />
  );
};

export { ActuCard };
