import { ServiceFavoriteButton } from '../ServiceFavoriteButton/ServiceFavoriteButton.component';
import { ServiceRating } from '../ServiceRating/ServiceRating.component';
import { useRouter } from 'next/router';
import { ContentType } from 'pleinchamp-api-client';
import React, { FC } from 'react';
import { ContentCard } from '@components/content/ContentCard/ContentCard.component';
import { ContentCardProps } from '@components/content/ContentCard/ContentCard.types';
import { Text } from '@components/Text/Text.component';
import { TextWithArrow } from '@components/Text/TextWithArrow.component';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { ServiceContentType } from '@utils/api.utils';
import { getSlugLinkProps, isExternal, urlEnum } from '@utils/url';
import classnames from 'classnames';

import styles from './ServiceCard.module.scss';

type Props = Omit<ContentCardProps, 'cardSubTitle' | 'cardActions' | 'href' | 'id'> & {
  id: number;
  slug: string;
  type: ServiceContentType;
  rating?: number;
  title: string;
  serviceUrl?: string;
  isFavoris?: boolean;
};

const ServiceCard: FC<Props> = ({
  id,
  slug,
  type,
  title,
  cardImage,
  rating,
  serviceUrl,
  className,
  isFavoris,
  ...rest
}) => {
  const url = serviceUrl || slug;
  const isAffiliate = type === ContentType.AffiliateService;
  const linkProps = isExternal(url) ? { href: url } : getSlugLinkProps(urlEnum.services, url);
  const serviceType = type === ContentType.AffiliateService ? 'affiliate' : 'plc';
  const rootClass = classnames(styles.serviceCard, styles[`service-card-${serviceType}`], className);
  const cardActions = <TextWithArrow i18nKey="common.access" tag="span" variant="p" />;
  const isMobile = useIsBreakpointDown(Breakpoint.s);
  const router = useRouter();
  const hFavoris = isFavoris ? 'h4' : 'h3';

  const finalCardImage = isAffiliate ? (
    cardImage
  ) : (
    <>
      {cardImage}
      <div className={`${styles.serviceCardPlcOverlay} flex column justify-center plc-px-s plc-px-up-s-m`}>
        <Text flavour="light" tag="p" variant={isMobile ? 'h6' : hFavoris}>
          {title}
        </Text>
      </div>
    </>
  );
  const cardHeaderBottom = rating ? <ServiceRating rating={rating} variant="small" /> : undefined;
  const hideFavoriteButton = router.pathname === '/meteo/[type]/[locality]';
  return (
    <ContentCard
      cardActions={cardActions}
      cardHeaderBottom={cardHeaderBottom}
      cardHeaderTop={hideFavoriteButton ? '' : <ServiceFavoriteButton serviceSlug={slug} withLabel={false} />}
      cardImage={finalCardImage}
      cardImageCentered={isAffiliate}
      className={rootClass}
      {...linkProps}
      {...rest}
    />
  );
};

export { ServiceCard };
