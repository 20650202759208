import { useTagPlan } from 'features/tagPlan/business/useTagPlan';
import Head from 'next/head';
import React, { ComponentProps, FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { TextProps } from '@components/Text/Text.types';
import { useTranslation } from '@locales/useTranslation.hook';
import { MetaInfos } from '@meteo/components/HourByHourLayout/HourByHourLayout.component';
import classnames from 'classnames';
import { PlcTagProps } from 'features/tagPlan/business/TagPlan.types';

export type MetasProps = {
  metaTitleI18n: string;
  metaTitleOptions?: TextProps['i18nOptions'];
  metaTitleOptionsForMeteo?: MetaInfos;
  metaDescI18n?: string;
  metaDescOptions?: TextProps['i18nOptions'];
  appNameAsPrefix?: boolean;
  canonical?: string;
};

type Props = ComponentProps<'main'> & MetasProps & { tagProps?: PlcTagProps };

const PlcMetaHead: FC<MetasProps> = ({
  metaTitleI18n,
  metaTitleOptions,
  metaTitleOptionsForMeteo,
  metaDescI18n,
  metaDescOptions,
  appNameAsPrefix,
  canonical,
}) => {
  const { t } = useTranslation(['common']);
  const appName = t('common.app-name');

  const fullTitle = useMemo(() => {
    if (metaTitleOptionsForMeteo) {
      const title = t(metaTitleI18n, metaTitleOptionsForMeteo);
      return appNameAsPrefix ? `${appName} - ${title}` : `${title} - ${appName}`;
    }
    const title = t(metaTitleI18n, metaTitleOptions);
    return appNameAsPrefix ? `${appName} - ${title}` : `${title} - ${appName}`;
  }, [metaTitleI18n, metaTitleOptions, metaTitleOptionsForMeteo, appNameAsPrefix]);

  useEffect(() => {
    document.title = fullTitle;
  }, []);

  return (
    <>
      <Head>
        <title key="title">{fullTitle}</title>
        {metaDescI18n && (
          <meta key="description" content={`${t(metaDescI18n, metaDescOptions)} - ${appName}`} name="description" />
        )}
        {canonical && <link key="canonical" href={canonical} rel="canonical" />}
      </Head>
    </>
  );
};

const PlcPageLayout: FC<PropsWithChildren<Props>> = ({
  className,
  children,
  metaTitleI18n,
  metaTitleOptions,
  metaTitleOptionsForMeteo,
  metaDescI18n,
  metaDescOptions,
  appNameAsPrefix,
  canonical,
  tagProps,
  ...rest
}) => {
  const rootClass = classnames('plc-page', className);

  const { tagPage } = useTagPlan();
  useEffect(() => {
    if (!tagProps || (tagProps && tagProps.isDataLoading)) {
      return;
    }
    tagPage(tagProps);
  }, [tagProps]);

  return (
    <main className={rootClass} {...rest}>
      <PlcMetaHead
        appNameAsPrefix={appNameAsPrefix}
        canonical={canonical}
        metaDescI18n={metaDescI18n}
        metaDescOptions={metaDescOptions}
        metaTitleI18n={metaTitleI18n}
        metaTitleOptions={metaTitleOptions}
        metaTitleOptionsForMeteo={metaTitleOptionsForMeteo}
      />
      {children}
    </main>
  );
};

export { PlcPageLayout, PlcMetaHead };
