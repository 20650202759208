export function formatCount(count: number) {
  let formatedCount: string = count.toString();
  if (count >= 1000 && count < 10000) {
    formatedCount = `${Math.round(count / 100) / 10}k`;
  } else if (count >= 10000) {
    formatedCount = `${Math.round(count / 1000)}k`;
  }
  return formatedCount;
}

export function formatNumberWithComma(num: number | string, precision = 0): string {
  let tempNum = num;
  if (typeof num === 'number') {
    if (precision > 0) {
      tempNum = (Math.round(num * 10 ** precision) / 10 ** precision).toString().replace('.', ',');
    } else {
      tempNum = Math.round(num).toString();
    }
  }
  // This will be always true as type number is casted to string before
  if (typeof tempNum === 'string') {
    const commaPosition = tempNum.indexOf(',') === -1 ? tempNum.length : tempNum.indexOf(',');
    let numberBeforeComma = tempNum.substring(0, commaPosition);
    const numberAfterComma = tempNum.substring(commaPosition);
    if (numberBeforeComma.length > 3) {
      numberBeforeComma = `${numberBeforeComma.substring(
        0,
        numberBeforeComma.length - 3
      )} ${numberBeforeComma.substring(numberBeforeComma.length - 3)}`;
    }
    return numberBeforeComma + numberAfterComma;
  }
  return '';
}
