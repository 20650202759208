import React from 'react';
import { SvgProps } from '@components/icons/icon.types';

function YellowSunIllu({ fillColor = '#FFD314', ...props }: SvgProps) {
  return (
    <svg viewBox="0 0 60 62" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.037 17.787C-2.145 27.733-.09 33.38 2.713 45.433c1.284 5.52 5.573 9.91 8.774 13.024 1.19 1.157 2.71 1.65 4.203 1.947 11.55 2.286 24.83 2.914 34.262-5.657 4.72-4.29 7.412-10.235 9.473-17.023C62.128 28.818 54.786 9.27 48.15 4.48c-2.409-1.738-5.135-2.689-7.873-3.335-4.199-.99-8.593-1.293-12.704.156-4.11 1.449-16.254 3.777-17.98 8.62l-5.556 7.865z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export { YellowSunIllu };
