import { formatDateCompact, formatDateToIso } from './date';
import { urlEnum } from './url';
import { GuideCard } from 'features/guides/components/GuideCard/GuideCard.component';
import { GuideEpisodesHeader } from 'features/guides/components/GuideEpisodesHeader/GuideEpisodesHeader.component';
import { InnovationCard } from 'features/innovations/components/InnovationCard/InnovationCard.component';
import { InnovationHighlightCard } from 'features/innovations/components/InnovationHighlightCard/InnovationHighlightCard.component';
import { ServiceCard } from 'features/services/components/ServiceCard/ServiceCard.component';
import compact from 'lodash/compact';
import {
  ActivityCode,
  Article,
  Content,
  ContentType,
  Guide,
  InterestCode,
  Service,
  Startup,
  Tag,
} from 'pleinchamp-api-client';
import React, { ComponentProps } from 'react';
import { ActuCard } from '@actus/components/ActuCard/ActuCard.component';
import { ClippedImage } from '@components/ClippedImage/ClippedImage.component';
import { ContentCardLight } from '@components/content/ContentCardLight/ContentCardLight.component';
import { PlcImgLoader } from '@components/Loaders/PlcImgLoader.component';
import { PlcImg } from '@components/PlcImg/PlcImg.component';
import { Text } from '@components/Text/Text.component';
import { TextWithArrow } from '@components/Text/TextWithArrow.component';
import { EventCard } from '@events/components/EventCard/EventCard.component';
import { PlcColor } from '@styles/colors';
import { ServiceContentType } from './api.utils';
import { ClippedImageStyle } from './images';

type Tags = (ActivityCode | InterestCode)[];

function getThumbnail(content: Article | Guide | Content | Startup, isMobile?: boolean) {
  switch (true) {
    case content.contentType === ContentType.Startup:
      return (content as Startup).logo;
    case Object.prototype.hasOwnProperty.call(content, 'articleDetail'):
      return isMobile && (content as Content).articleDetail?.thumbnailMediaMobile
        ? (content as Content).articleDetail?.thumbnailMediaMobile
        : (content as Content).articleDetail?.thumbnailMedia;
    case Object.prototype.hasOwnProperty.call(content, 'guideDetail'):
      return isMobile && (content as Content).guideDetail?.thumbnailMediaMobile
        ? (content as Content).guideDetail?.thumbnailMediaMobile
        : (content as Content).guideDetail?.thumbnailMedia;
    case Object.prototype.hasOwnProperty.call(content, 'serviceDetail'):
      return isMobile && (content as Content).serviceDetail?.thumbnailMediaMobile
        ? (content as Content).serviceDetail?.thumbnailMediaMobile
        : (content as Content).serviceDetail?.thumbnailMedia;
    default:
      return isMobile && (content as Article | Guide).thumbnailMediaMobile
        ? (content as Article | Guide).thumbnailMediaMobile
        : (content as Article | Guide).thumbnailMedia;
  }
}

function getThumbnailImage(
  content: Article | Guide | Content | Startup,
  loaderProps?: Omit<ComponentProps<typeof PlcImgLoader>, 'uniqueKey'>,
  height?: number,
  isMobile?: boolean
) {
  const thumbnailMedia = getThumbnail(content, isMobile);

  return thumbnailMedia ? (
    <PlcImg
      alt=""
      loader={<PlcImgLoader height={height ?? 272} uniqueKey={`thumbnailMedia-${thumbnailMedia.id}`} width={325} {...loaderProps} />}
      plcSrc={{ src: thumbnailMedia.url, type: 'external' }}
    />
  ) : null;
}

function buildActuCard(content: Article | Content, tags: Tags, isMobile?: boolean) {
  if (content.contentType !== ContentType.Article) {
    return null;
  }

  const { title, publishDate, isPro, slug } = content;

  return (
    <ActuCard
      key={slug}
      cardImage={getThumbnailImage(content, undefined, undefined, isMobile)}
      cardTitle={title}
      date={publishDate}
      isPro={isPro}
      slug={slug}
      tags={tags}
    />
  );
}

function buildEventCard(content: Content) {
  if (content.contentType !== ContentType.Event || !content.eventDetail) {
    return null;
  }

  const { eventDetail, slug, title } = content;
  const { startDate, endDate, address } = eventDetail;

  return (
    <EventCard key={slug} address={address} cardTitle={title} endDate={endDate} slug={slug} startDate={startDate} />
  );
}

function buildGuideCard(content: Guide | Content, tags: Tags) {
  if (content.contentType !== ContentType.Guide) {
    return null;
  }

  const { title, isPro } = content;
  const episodesList = Object.prototype.hasOwnProperty.call(content, 'guideDetail')
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (content as Content).guideDetail!.episodeList
    : (content as Guide).episodeList;
  const slug = episodesList[0];

  return (
    <GuideCard
      key={slug}
      cardImage={getThumbnailImage(content)}
      cardTitle={title}
      episodesCount={episodesList.length - 1}
      isPro={isPro}
      slug={slug}
      tags={tags}
      title={title}
    />
  );
}

function isService(content: Service | Content): content is Service {
  return !Object.prototype.hasOwnProperty.call(content, 'serviceDetail');
}

function buildServiceCard(content: Service | Content, isFavoris: boolean | undefined) {
  const serviceContentTypes = [ContentType.PlcService, ContentType.AffiliateService];
  if (!serviceContentTypes.includes(content.contentType)) {
    return null;
  }

  const { id, title, slug, isPro, contentType } = content;
  const { affiliateServiceDetail, plcServiceDetail, subtitle } = isService(content)
    ? content
    : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      content.serviceDetail!;

  return slug ? (
    <ServiceCard
      key={slug}
      cardImage={getThumbnailImage(content, { fill: PlcColor.WHITE, withSun: false })}
      cardTitle={subtitle}
      id={id}
      isFavoris={isFavoris}
      isPro={isPro}
      rating={affiliateServiceDetail?.averageRating}
      serviceUrl={plcServiceDetail?.serviceURL}
      slug={slug}
      title={title}
      type={contentType as ServiceContentType}
    />
  ) : null;
}

export function buildTagList(activityCodes: Content['activityCodes'], interestCodes: Content['interestCodes']) {
  let tags: (ActivityCode | InterestCode | undefined)[] = [];
  tags = tags.concat(activityCodes, interestCodes);
  return compact(tags);
}

type CardContent = Article | Guide | Service | Content;

export const buildContentCard = (content: CardContent, isMobile?: boolean) => {
  switch (content.contentType) {
    case ContentType.Article: {
      const tags = buildTagList(content.activityCodes, content.interestCodes);
      return buildActuCard(content, tags, isMobile);
    }
    case ContentType.Event: {
      return buildEventCard(content);
    }
    case ContentType.Guide: {
      const tags = buildTagList(content.activityCodes, content.interestCodes);
      return buildGuideCard(content, tags);
    }
    case ContentType.PlcService:
    case ContentType.AffiliateService: {
      return buildServiceCard(content, false);
    }
    default: {
      return null;
    }
  }
};
export const buildContentFavorisServiceCard = (content: CardContent) => {
  switch (content.contentType) {
    case ContentType.Article: {
      const tags = buildTagList(content.activityCodes, content.interestCodes);
      return buildActuCard(content, tags);
    }
    case ContentType.Event: {
      return buildEventCard(content);
    }
    case ContentType.Guide: {
      const tags = buildTagList(content.activityCodes, content.interestCodes);
      return buildGuideCard(content, tags);
    }
    case ContentType.PlcService:
    case ContentType.AffiliateService: {
      return buildServiceCard(content, true);
    }
    default: {
      return null;
    }
  }
};

export function generateLightCardFromArticle(content: Content) {
  if (content.contentType !== ContentType.Article) {
    return <></>;
  }

  const { publishDate, isPro, slug, title } = content;
  const header = (
    <Text dateTime={formatDateToIso(publishDate)} tag="time" variant="h6">
      {formatDateCompact(publishDate)}
    </Text>
  );

  return <ContentCardLight key={slug} header={header} href={urlEnum.actu} isPro={isPro} slug={slug} title={title} />;
}

export function generateLightCardFromGuide(content: Content) {
  if (content.contentType !== ContentType.Guide || !content.guideDetail) {
    return <></>;
  }
  const { isPro, title, guideDetail } = content;
  const { episodeList } = guideDetail;
  const episodesCount = episodeList.length - 1;
  const slug = episodeList[0];
  const header = <GuideEpisodesHeader count={episodesCount} />;

  return <ContentCardLight key={slug} header={header} href={urlEnum.guides} isPro={isPro} slug={slug} title={title} />;
}

/**
 * Build the startup card
 * @param content
 * @param domainsList as Tag[]
 * @param stakesList as Tag[]
 * @param sectorsList as Tag[]
 * @param isHighlightCard
 * @returns Startup component
 */
export function buildStartupCard(
  content: Startup,
  domainsList: Tag[],
  stakesList: Tag[],
  sectorsList: Tag[],
  isHighlightCard?: boolean
) {
  const { name, description, publishDate, slug, stakeCodes, domainCodes, sectorCodes } = content;

  const domains =
    domainsList && domainCodes
      ? domainsList.filter(domain =>
          domainCodes.some(
            domainCode =>
              domainCode === domain.remoteId ||
              (domain.children ? domain.children.map(e => e.remoteId).includes(domainCode) : false)
          )
        )
      : ([] as Tag[]);
  const sectors =
    sectorsList && sectorCodes
      ? sectorsList.filter(sector => sectorCodes.some(sectorCode => sectorCode === sector.remoteId))
      : ([] as Tag[]);
  const stakes =
    stakesList && stakeCodes
      ? stakesList.filter(stake => stakeCodes.some(stakeCode => stakeCode === stake.remoteId))
      : ([] as Tag[]);

  return (
    <InnovationCard
      key={slug}
      cardImage={getThumbnailImage(content, {}, 150)}
      date={publishDate}
      description={description}
      domains={domains}
      isHighlightCard={isHighlightCard}
      sectors={sectors}
      slug={slug}
      stakes={stakes}
      title={name}
    />
  );
}

/**
 * Build the highlight startup card
 * @param content HighLightStartupCard Component
 * @param domainsList as Tag[]
 * @param stakesList as Tag[]
 * @param sectorsList as Tag[]
 */
export function buildHighlightStartupCard(content: Startup, domainsList: Tag[], stakesList: Tag[], sectorsList: Tag[]) {
  const { name, description, publishDate, slug, stakeCodes, domainCodes, sectorCodes } = content;

  const domains = domainsList
    ? domainsList.filter(domain =>
        domainCodes.some(
          domainCode =>
            domainCode === domain.remoteId ||
            (domain.children ? domain.children.map(e => e.remoteId).includes(domainCode) : false)
        )
      )
    : ([] as Tag[]);
  const sectors = sectorsList
    ? sectorsList.filter(sector => sectorCodes.some(sectorCode => sectorCode === sector.remoteId))
    : ([] as Tag[]);
  const stakes = stakesList
    ? stakesList.filter(stake => stakeCodes.some(stakeCode => stakeCode === stake.remoteId))
    : ([] as Tag[]);

  const thumbnailMedia = content.image;
  const clippedImage = (
    <ClippedImage
      alt={thumbnailMedia?.alt || ''}
      clipStyle={ClippedImageStyle.innovationHighlightcard}
      plcSrc={{ src: thumbnailMedia?.url || '', type: 'external' }}
    />
  );
  const actions = <TextWithArrow i18nKey="innovations.card-action" tag="span" variant="p" />;

  return (
    <InnovationHighlightCard
      cardActions={actions}
      cardImage={clippedImage}
      date={publishDate}
      description={description}
      domains={domains}
      sectors={sectors}
      slug={slug}
      stakes={stakes}
      startUpImage={getThumbnailImage(content, {}, 100)}
      title={name}
    />
  );
}
