import React, { ComponentProps, FC } from 'react';
import { ProTag } from '@components/ProTag/ProTag.component';
import { Text } from '@components/Text/Text.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { getSlugLinkProps, urlEnum } from '@utils/url';
import classnames from 'classnames';

interface Props extends Omit<ComponentProps<'article'>, 'title'> {
  title: string;
  header: JSX.Element;
  href: urlEnum;
  slug: string;
  isPro?: boolean;
}

export const ContentCardLight: FC<Props> = ({ title, header, href, slug, isPro = false, className, ...rest }) => {
  const linkProps = getSlugLinkProps(href, slug);
  const isMobile = useIsBreakpointDown(Breakpoint.m);
  const titleVariant = isMobile ? 'h5' : 'h4';
  const rootClassName = classnames('content-card-light', className);

  return (
    <PlcAnchor linkProps={linkProps} niveau3={href === urlEnum.guides ? title : ''}>
      <article className={rootClassName} {...rest}>
        <div className="plc-mb-s flex align-center">
          {header}
          {isPro && <ProTag className="plc-ml-xs plc-mr-0" />}
        </div>
        <Text className="plc-mb-s flex align-center" tag="div" variant={titleVariant}>
          {title}
        </Text>
      </article>
    </PlcAnchor>
  );
};
