import React from 'react';
import { SquareSvgProps } from '@icons/icon.types';

function StarFullIcon({ title, size = 24, fillColor = '#84AF4B', ...props }: SquareSvgProps) {
  return (
    <svg height={size} viewBox="0 0 22 20" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
      {title && <title>{title}</title>}
      <path
        d="M11 0l3.245 6.584L21.5 7.639l-5.25 5.125L17.49 20 11 16.584 4.51 20l1.24-7.236L.5 7.639l7.255-1.055z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

function StarEmptyIcon({ title, size = 24, fillColor = '#2D3049', ...props }: SquareSvgProps) {
  return (
    <svg height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
      {title && <title>{title}</title>}
      <path
        d="M12 2l3.245 6.584L22.5 9.639l-5.25 5.125L18.49 22 12 18.584 5.51 22l1.24-7.236L1.5 9.639l7.255-1.055L12 2zm1.916 8.411L12 6.524l-1.916 3.887-4.291.624 3.106 3.031-.733 4.275L12 16.323l3.833 2.018-.732-4.275 3.105-3.031-4.29-.624z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

interface Props extends SquareSvgProps {
  isFavorite: boolean;
}

function StarFavoriteIcon({ isFavorite, ...props }: Props) {
  if (isFavorite) {
    return <StarFullIcon {...props} />;
  }
  return <StarEmptyIcon {...props} />;
}

export { StarFavoriteIcon };
