import { InnovationTagList } from '../InnovationTagList/InnovationTagList';
import React, { FC } from 'react';
import { Text } from '@components/Text/Text.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { getSlugLinkProps, urlEnum } from '@utils/url';
import classNames from 'classnames';
import { InnovationContentCardProps } from '../InnovationContentCard/InnovationContentCard.types';

import styles from './InnovationHighlightCard.module.scss';

type Props = Omit<InnovationContentCardProps, 'subTitle' | 'actions' | 'href'> & {
  slug: string;
  date: Date;
  title: string;
  startUpImage?: React.ReactNode;
};

export const InnovationHighlightCard: FC<Props> = ({
  cardImage,
  cardSubTitle,
  title,
  cardActions,
  cardHeaderTop,
  cardHeaderBottom,
  description,
  className,
  startUpImage,
  index,
  slug,
  stakes = [],
  domains = [],
  sectors = [],
  ...rest
}) => {
  const rootClass = classNames(`${styles['inno-cardHighlight']} plc-my-m`, className);
  const linkProps = getSlugLinkProps(urlEnum.innovations, slug);
  const rootClassNameSector = classNames('content-tags-list', 'plc-tag-sector');
  const rootClassNameStakes = classNames('content-tags-list', 'plc-tag-stake');
  const rootClassNameDomain = classNames('content-tags-list', 'plc-tag-domain');

  return (
    <PlcAnchor linkProps={{ ...linkProps }}>
      <article className={rootClass} {...rest}>
        <div className={`${styles['inno-cardHighlight__content']} flex`}>
          <div className={styles["inno-cardHighlight__img"]}>{cardImage}</div>
          <div className={`${styles['inno-cardHighlight__main']} flex column flex-1`}>
            {startUpImage}
            <Text className="plc-mb-s" tag="p" variant="h4">
              {title}
            </Text>
            <Text className={`plc-mb-s ${styles['text-overflow']}`} variant="small">
              {description}
            </Text>
            {cardActions && <div className="">{cardActions}</div>}
          </div>
          <div className={`${styles['inno-cardHighlight__aside']} flex column flex-1`}>
            <div className={styles["inno-cardHighlight__selectedCa"]}>
              <Text i18nKey="innovations.filter.selection.villageca" tag="p" variant="h4" />
            </div>
            {sectors.length > 0 && <InnovationTagList className={rootClassNameSector} oneLine={true} tags={sectors} />}
            {stakes.length > 0 && <InnovationTagList className={rootClassNameStakes} oneLine={true} tags={stakes} />}
            {domains.length > 0 && <InnovationTagList className={rootClassNameDomain} oneLine={true} tags={domains} />}
          </div>
        </div>
      </article>
    </PlcAnchor>
  );
};
