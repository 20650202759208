import { EventDetail } from 'pleinchamp-api-client';
import { plcDayjs } from '@utils/date';
import { Dayjs } from 'dayjs';

export const getEventFormatedDate = (
  startDate?: EventDetail['startDate'] | Dayjs,
  endDate?: EventDetail['endDate'] | Dayjs
) => {
  const start = startDate ? plcDayjs(startDate).startOf('day') : null;
  const end = endDate ? plcDayjs(endDate).startOf('day') : null;
  if (!start) {
    return [''];
  }

  // 1. Same day
  if (!end || start.isSame(end, 'day')) {
    return [`${start.format('DD MMMM')}`, `${start.format('YYYY')}`];
  }

  // 2. Same month
  if (start.year() === end.year() && start.month() === end.month()) {
    return [`${start.format('DD')}-${end.format('DD')}`, `${start.format('MMMM')}`, `${start.format('YYYY')}`];
  }

  // 3. Same year
  if (start.year() === end.year()) {
    return [`${start.format('DD MMMM')} -`, end.format('DD MMMM'), start.format('YYYY')];
  }

  // 4. Totally different
  return [`${start.format('DD MMM YYYY')} -`, end.format('DD MMM YYYY')];
};
