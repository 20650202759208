/* eslint-disable no-irregular-whitespace */
import { InnovationTagList } from '../InnovationTagList/InnovationTagList';
import React, { FC } from 'react';
import { Text } from '@components/Text/Text.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import classNames from 'classnames';
import { InnovationContentCardProps } from './InnovationContentCard.types';

const InnovationContentCard: FC<InnovationContentCardProps> = ({
  cardImage,
  cardImageCentered = false,
  cardSubTitle,
  cardTitle,
  cardActions,
  cardHeaderTop,
  cardHeaderBottom,
  description,
  isHighlightCard = false,
  href,
  as,
  className,
  index,
  stakes = [],
  domains = [],
  sectors = [],
  ...rest
}) => {
  const [animationState, setAnimationState] = React.useState(index ? 'paused' : 'running');

  React.useEffect(() => {
    if (!index) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
    const timeoutId = setTimeout(() => setAnimationState('running'), index * 150);
    return () => clearTimeout(timeoutId);
  });

  const rootClass = classNames(`innovation-card flex column plc-mb-s`, animationState, className);
  const cardImageClass = classNames('card-image', { centered: cardImageCentered });
  const rootClassNameSector = classNames('content-tags-list', 'plc-tag-sector');
  const rootClassNameStakes = classNames('content-tags-list', 'plc-tag-stake');
  const rootClassNameDomain = classNames('content-tags-list', 'plc-tag-domain');

  const innovation = (
    <article className={rootClass} {...rest}>
      <header>
        {isHighlightCard && (
          <div className={"card-highlighted"}>
            <Text i18nKey="innovations.filter.selection.villageca-mobile" tag="p" variant="h4" />
          </div>
        )}
        {cardImage && <div className={cardImageClass}>{cardImage}</div>}
        <div className={`flex column flex-1 plc-mb-xs min-height plc-mt-m plc-mt-up-m-0`}>
          <Text className={`card-title plc-mb-s`} tag="p" variant="h4">
            {cardTitle}
          </Text>
          <Text className={"card-description"} variant="small">
            {description}
          </Text>
          <div className={"card-header-bottom"}>
            {cardActions && <div className={"card-actions"}>{cardActions}</div>}
            {sectors.length > 0 && <InnovationTagList className={rootClassNameSector} oneLine={true} tags={sectors} />}
            {stakes.length > 0 && <InnovationTagList className={rootClassNameStakes} oneLine={true} tags={stakes} />}
            {domains.length > 0 && <InnovationTagList className={rootClassNameDomain} oneLine={true} tags={domains} />}
          </div>
        </div>
      </header>
    </article>
  );

  if (!href) {
    return innovation;
  }

  return <PlcAnchor linkProps={{ as, href }}>{innovation}</PlcAnchor>;
};

export { InnovationContentCard };
