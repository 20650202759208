import { LOADER_FONT_SIZE_RATIO, PlcContentLoader, PlcRect } from './PlcContentLoader.component';
import React, { ComponentProps, ComponentPropsWithRef, FC } from 'react';
import { TextProps } from '@components/Text/Text.types';
import { Spacing } from '@styles/Spacing.style';
import { TextStyle } from '@styles/Text.style';

export const PlcTextLoader: FC<Omit<ComponentPropsWithRef<'rect'>, 'heigth'> & {
  variant?: TextProps['variant'];
  numberOfLine?: number;
}> = ({ variant = 'p', numberOfLine = 1, y = 0, width, ...rest }) => {
  const { lineHeight, fontSize } = TextStyle[variant] || TextStyle.p;
  const elements: JSX.Element[] = [];
  const height = fontSize * LOADER_FONT_SIZE_RATIO;
  for (let i = 0; i < numberOfLine; i += 1) {
    const newY = Number(y) + (lineHeight - height) / 2 + i * lineHeight;
    elements.push(
      <PlcRect
        key={i}
        height={height}
        width={i > 0 && i === numberOfLine - 1 ? 0.6 * Number(width) : width}
        y={newY}
        {...rest}
      />
    );
  }
  return <>{elements}</>;
};

export const PlcTextListLoader: FC<{
  textProps: (Omit<ComponentProps<typeof PlcTextLoader>, 'y'> & { bottomSpacing?: Spacing })[];
} & ComponentProps<typeof PlcContentLoader>> = ({ textProps, width, uniqueKey, ...rest }) => {
  const textPropsWithOffset = textProps.reduce((acc, props) => {
    const { variant = 'p', bottomSpacing, numberOfLine = 1, ...restTextProps } = props;
    const y = acc.length ? acc[acc.length - 1].nextSpacing : 0;
    const nextSpacing = y + numberOfLine * TextStyle[variant].lineHeight + Number(bottomSpacing || 0);
    acc.push({ nextSpacing, numberOfLine, variant, y, ...restTextProps });
    return acc;
  }, [] as (ComponentProps<typeof PlcTextLoader> & { nextSpacing: number })[]);
  const height = textPropsWithOffset.length ? textPropsWithOffset[textPropsWithOffset.length - 1].nextSpacing : 0;
  const maxTextWidth = textProps.reduce((acc, props) => (acc > Number(props.width) ? acc : Number(props.width)), 0);
  return (
    <PlcContentLoader height={height} uniqueKey={uniqueKey} width={width || maxTextWidth} {...rest}>
      {textPropsWithOffset.map(({ nextSpacing: _, ...filteredProps }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <PlcTextLoader key={i} {...filteredProps} />
      ))}
    </PlcContentLoader>
  );
};
