import React from 'react';
import { SvgProps } from './icon.types';

export function GuideIcon({ title, fillColor = '#2D3049', ...props }: SvgProps) {
  return (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      {title && <title>{title}</title>}
      <g fill={fillColor} fillRule="evenodd">
        <path d="M3 1v16h13V1H3zm2 14h9V3H5v12z" />
        <path d="M1 9.994h6v-2H1zM1 13h6v-2H1zM1 7h6V5H1zM8 7h4V5H8z" />
      </g>
    </svg>
  );
}
