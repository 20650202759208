import React, { ComponentPropsWithRef, FC, MouseEvent } from 'react';
import { IconButton } from '@components/buttons/IconButton/IconButton.component';
import { Text } from '@components/Text/Text.component';
import { StarFavoriteIcon } from '@icons/StarFavorite.icon';
import classnames from 'classnames';

type Props = Omit<ComponentPropsWithRef<'button'>, 'onClick'> & {
  isFavorite: boolean;
  withLabel?: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const FavoriteButton: FC<Props> = ({ isFavorite, withLabel = false, onClick, className, ...rest }) => {
  const rootClass = classnames('favorite-button inline-flex', className);
  return (
    <IconButton className={rootClass} onClick={onClick} {...rest}>
      <StarFavoriteIcon isFavorite={isFavorite} />
      {withLabel && (
        <Text
          className="plc-ml-xs"
          i18nKey={isFavorite ? 'common.added-to-favorite' : 'common.add-to-favorite'}
          tag="span"
          variant="small"
        />
      )}
    </IconButton>
  );
};
