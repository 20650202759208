import React from 'react';
import { SvgProps } from './icon.types';

function TinnyPinIcon({ fillColor = '#2D3049', ...props }: SvgProps) {
  return (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 2c4.527 0 5.659 3.462 5.659 3.462C15.938 9 13.527 11.385 9 16 4.473 11.385 2.062 9 3.341 5.462 3.341 5.462 4.473 2 9 2zm0 2C5.958 4 5.249 6.062 5.242 6.083l-.009.029-.011.03c-.712 1.968.395 3.493 3.774 6.991 4.338-4.545 4.351-5.416 3.782-6.991l-.011-.03-.009-.029C12.689 5.9 11.9 4 9 4zm0 2a1 1 0 110 2 1 1 0 010-2z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export { TinnyPinIcon };
