import React, { FC } from 'react';
import { Tag, TagProps } from '@components/Tag/Tag.component';
import { useTranslation } from '@locales/useTranslation.hook';
import classnames from 'classnames';

import styles from './ProTag.module.scss';

export enum ProTagVariant {
  normal = 'normal',
  long = 'long',
}

type Props = Omit<TagProps, 'onClose'> & {
  variant?: ProTagVariant;
};

const ProTag: FC<Props> = ({ variant = ProTagVariant.normal, className, ...rest }) => {
  const { t } = useTranslation(['common']);
  const label = t(`common.pro.label-${variant}`);
  const rootClassName = classnames(styles['plc-pro-tag'], className);
  return (
    <Tag className={rootClassName} isPro {...rest}>
      {label}
    </Tag>
  );
};

export { ProTag };
